<template>
  <div class="googleMaps_wrapper">
    <div class="map-container" ref="mapContainer"></div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: 'GoogleMap',
  props: {
    locations: {
      type: Array,
      required: true,
    },
    isMarkersClickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      map: null,
      center: {lat: 40.731266, lng: -73.997915},
      markers: [],
      infoWindows: [],
      selectedLocation: null,
    };
  },
  mounted() {
    if (this.locations && this.locations[0].id) {
      this.initMap();
    }
  },
  watch: {
    locations() {
      this.initMap();
    },
  },
  methods: {
    async initMap() {
      let center = undefined;
      let zoom = 14;
      const isMobile = window.innerWidth <= 992;
      let showInfoWindow = false;

      if (this.locations?.length === 1) {
        const pos = structuredClone(this.locations[0].position);
        console.log('locations', this.locations);
        pos.lat = pos.lat + 0.001;
        center = pos;
        zoom = isMobile ? 16: 12;
        showInfoWindow = true;
      } else {
        zoom = isMobile ? 11: 14;
        center = {lat: 40.731266, lng: -73.997915};
      }

      this.map = new google.maps.Map(this.$refs.mapContainer, {
        center: center,
        mapId: 'PrestigeBookingMap',
        zoom: zoom,
        disableDefaultUI: true,
        draggable: true,
        zIndex: 10
      });

      this.locations.forEach((location, index) => {

        if (location.position) {
          const marker = new google.maps.Marker({
            position: location.position,
            map: this.map,
            title: location.name,
            clickable: this.isMarkersClickable,
          });

          const infoWindow = new google.maps.InfoWindow(this.getInfoWindowOptions(location));

          if (showInfoWindow) {
            infoWindow.open(this.map, marker);
          }

          marker.addListener('click', () => {
            this.closeAllInfoWindows();
            this.selectLocation(index);
            infoWindow.open(this.map, marker);
          });

          this.markers.push(marker);
          this.infoWindows.push(infoWindow);
        }
      });
    },
    getInfoWindowOptions(location) {
      const headerContent = document.createElement('h3');
      headerContent.innerText = location.name;
      headerContent.className = 'infoWindowHeader';

      const content = `
        <div class="custom-label">
            ${location.phoneNumber ? ('<b>Phone:</b>&nbsp;<a href="tel:'+location.phoneNumber+'">'+location.phoneNumber+'</a><br/>') : ''}
            ${location.address ? ('<b>Address:</b>&nbsp;'+location.address+'<br/>') : ''}
        </div>`;

      return {
        headerContent,
        content,
        maxWidth: 200,
        disableAutoPan: true,
      }
    },
    selectLocation(index) {
      if (!this.isMarkersClickable) {
        return;
      }

      const location = this.locations[index];
      this.$emit('set-selected-location', location?.id);
      this.selectedLocation = index;
    },
    closeAllInfoWindows() {
      this.infoWindows.forEach(infoWindow => {
        infoWindow.close();
      });
    },
  },
});
</script>

<style>

.custom-label {
  padding: 0 5px 5px 0;
  border-radius: 3px;
  font-size: 12px;
}

.infoWindowHeader {
  font-weight: 600;
}
</style>

<style scoped>
.googleMaps_wrapper {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.location-info {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
</style>
