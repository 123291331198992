
import Vue from 'vue';
import ExtendedDoctorCard from '@/components/cards/ExtendedDoctorCard.vue';
import {mapGetters} from 'vuex';

export default Vue.extend({
  name: 'SelectTime',
  components: {ExtendedDoctorCard},
  props: {
    selectedDoctor: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      slots: [],
      slotsKeys: [],
      firstDate: undefined,
      lastDate: undefined,
    }
  },
  created() {
    this.setSlots(this.selectedDoctor?.slots);

    this.setDates();

    const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
    if (hash) {
      this.scrollToDate(hash);
    } else {
      this.scrollToTop();
    }
  },
  watch: {
    selectedDoctor(val, oldVal) {
      if (val.externalId !== oldVal.externalId) {
        this.setSlots(this.selectedDoctor?.slots);
      }
    },
    slots: 'setDates',
    '$route.hash': function(newHash) {

      const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
      if (hash) {
        this.scrollToDate(hash);
      } else {
        this.scrollToTop();
      }
    }
  },
  computed: {
    ...mapGetters(['bookingLoading']),
    nextDates() {
      const currentNextDate = this.lastDate.object.clone();
      const start = currentNextDate.add(1, 'day');
      const end = start.add(7, 'day');

      return {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
      };
    },
    prevDates() {
      const currentLastDate = this.firstDate.object.clone();
      const end = currentLastDate.subtract(1, 'day');
      const start = end.subtract(7, 'day');

      return {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
      };
    },
    isPrevWeekDisabled() {
      const today = this.$dayjs().startOf('day');
      const prevStartDate = this.$dayjs(this.prevDates.start);
      return prevStartDate.isBefore(today);
    }
  },
  methods: {
    scrollToDate(date) {
      this.$nextTick(() => {
        const dateContainer = this.$refs[`date-${date}`];
        if (dateContainer && dateContainer[0]) {
          dateContainer[0].scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    selectTime(date: String,time: String) {
      const dateTimeStr = `${date} ${time}`;
      const timeObj = this.$dayjs.tz(dateTimeStr, 'YYYY-MM-DD h:mm A', 'US/Eastern').toISOString();

      this.$emit('set-selected-time', timeObj);
    },
    setSlots(slots) {
      this.slotsKeys = Object.keys(slots);

      const slotsArrKeys = this.slotsKeys;

      this.slots = slotsArrKeys.map((day) => {
        return {
          dateLabel: this.$dayjs(day).format('dddd, MMMM D'),
          date: this.$dayjs(day).format('YYYY-MM-DD'),
          times: slots[day],
          timesCount: slots[day].length,
        };
      });
    },
    nextWeekSlots() {
      this.requestSlots(this.nextDates.start, this.nextDates.end);
    },
    prevWeekSlots() {
      this.requestSlots(this.prevDates.start, this.prevDates.end);
    },
    async requestSlots(startDate, endDate) {
      const payload = {
        data: {
          doctor_external_id: this.selectedDoctor?.externalId,
          template_id: this.selectedDoctor?.templateId,
          startDate: startDate,
          endDate: endDate,
        }
      };

      const slots = await this.$store.dispatch('LOAD_SLOTS_FOR_DOCTOR', payload);

      if (!!slots) {
        this.setSlots(slots);
      }
    },
    setDates() {
      let firstDate = null;
      let lastDate = null;

      if (this.slots.length > 0) {
        firstDate = this.$dayjs(this.slots[0].date);
        lastDate = this.$dayjs(this.slots[this.slots.length - 1].date);
      }

      this.firstDate = {
        label: firstDate ? firstDate.format('D MMMM') : null,
        object: firstDate ?? null,
      };

      this.lastDate = {
        label: lastDate ? lastDate.format('D MMMM') : null,
        object: lastDate ?? null,
      };
    }
  }
});
